import React, { useState, useEffect } from "react";
import axios from 'axios'

import { Table, Button, Input } from 'reactstrap';

import LineList from './LineList'
import SearchLine from './SearchLine'
import PageNav from '../PageNav'
import PageCount from "../PageCount";
import { useHistory } from "react-router-dom";
import { getCookie } from "../../ReactCookie";
import { useLocation } from "react-router-dom";
import '../../css/Tables.css';

import RePagination from "../RePagination";
import { confirmAlert } from "react-confirm-alert";

const base_search = {
    page: 1,
    limit: 20,
    total_count: 0,
    corp_name: "",
    line_number: "",
    local_number: "",
    searchData: "",
    nowPage: 1,
    centrix_auth: "",
    use_yn: "",
    discd: "",
}

function LineTable(props) {

    const [search, setSearch] = useState(base_search);
    const [lines, setLines] = useState([]);
    const history = useHistory();

    /*
    const location = useLocation() 
    if (history.location.state && history.location.state.nowPage) {
        let state = { ...history.location.state };
        //delete state.transaction;
        history.replace({ ...history.location, state });
    }
    */

    useEffect(() => {
        if (history.location.state) {
            if (history.location.state.nowPage === 0) {
                searchList(1, history.location.state.searchData);
            } else {
                searchList(history.location.state.nowPage, history.location.state.searchData, history.location.state.centrix_auth, history.location.state.use_yn, history.location.state.discd);
            }
        } else searchList(1);
    }, []);

    function searchList(num, searchData, centrix_auth, use, discd) {

        let data = "";

        if (typeof searchData == "undefined" || searchData == null || searchData == "") data = search.searchData;
        else data = searchData;


        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/lines/list';
        // url_path += "&limit=" + search.limit;
        // url_path += "&searchData=" + data;
        // url_path += "&sms_yn=" + search.sms_yn;
        // url_path += "&use_yn=" + search.use_yn;
        // url_path += "&sms_yn=" + (history.location.state ? history.location.state.sms_yn : search.sms_yn);
        // url_path += "&use_yn=" + (history.location.state ? history.location.state.use_yn : search.use_yn);

        //url_path+="&corp_name="+search.corp_name;
        //url_path+="&line_number="+search.line_number;
        //url_path+="&local_number="+search.local_number;


        axios.get(url_path
            , {
                params: {
                    page: num,
                    limit: search.limit,
                    searchData: data,
                    centrix_auth: centrix_auth === undefined ? search.centrix_auth : centrix_auth,
                    use_yn: use === undefined ? search.use_yn : use,
                    discd: use === undefined ? search.discd : discd
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getCookie("access_token")
                }
            }
        )
            .then(res => {
                if (res.data.res_code === 200) {
                    setSearch(
                        {
                            ...search,
                            page: res.data.page,
                            nowPage: res.data.page,
                            limit: res.data.limit,
                            total_count: res.data.total_count,
                            searchData: data,
                            centrix_auth: res.config.params.centrix_auth !== undefined ? res.config.params.centrix_auth : search.centrix_auth,
                            use_yn: res.config.params.use_yn !== undefined ? res.config.params.use_yn : search.use_yn,
                            discd: res.config.params.discd !== undefined ? res.config.params.discd : search.discd,
                        }
                    );

                    if (res.config.params.use_yn === 'N') setShowAllcheckbox(true);
                    else setShowAllcheckbox(false);

                    setResultparams({
                        type: '',
                        searchData: data,
                        centrix_auth: res.config.params.centrix_auth !== undefined ? res.config.params.centrix_auth : search.centrix_auth,
                        use_yn: res.config.params.use_yn !== undefined ? res.config.params.use_yn : search.use_yn,
                        discd: res.config.params.discd !== undefined ? res.config.params.discd : search.discd,
                    })

                    setCheckedline([])

                    if (res.data.data === null) {
                        setLines([]);
                    } else {
                        setLines(res.data.data);
                    }


                } else {
                    if (res.data.res_code === 401) {
                        history.push('/login');
                    } else {
                        alert(res.data.res_msg);
                    }
                }
            });

    }

    function handleList(corp_idx) {
        let uri = '/corp/' + corp_idx;
        //ev.preventDefault();
        // history.push(uri);
        props.history.push({ pathname: uri, state: { nowPage: search.nowPage, searchData: search.searchData, prevPath: history.location.pathname, centrix_auth: search.centrix_auth, use_yn: search.use_yn, discd: search.discd } });
    }


    function handleEdit(ev, line_idx, page, data) {
        let uri = '/line/' + line_idx;
        //ev.preventDefault();
        history.push(
            {
                pathname: uri,
                state: {
                    nowPage: page,
                    searchData: data,
                    centrix_auth: search.centrix_auth,
                    use_yn: search.use_yn,
                    discd: search.discd,
                }
            }
        );
    }

    function handleRemove() {

        // const arr_rem = [];
        // lines.map(
        //     line => {
        //         if(line.checked){
        //             arr_rem.push(line.line_idx);
        //             //setRemoveIdx([...removeIdx, corp.corp_idx]);
        //         }
        //     }
        // );
        // if(arr_rem.length===0){
        //     alert('체크박스를 선택해주세요');
        //     return;
        // }

        if (checkedline.length === 0) {
            alert('체크박스를 선택해주세요');
            return;
        }

        if (!window.confirm('선택한 회선을 사용 해지하시겠습니까?')) {
            return;
        }

        let json = { "line_idx": checkedline };

        axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/lines/delete', json
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getCookie("access_token")
                }
            }
        )
            .then(response => {
                if (response.data.res_code === 200) {
                    searchList(search.page);

                } else {
                    if (response.data.res_code === 401) {
                        history.push('/login');
                    } else {
                        alert(response.data.res_msg);
                    }

                }
            });
    }

    function setSearchData(name, value) {
        setSearch(
            {
                ...search,
                [name]: value
            }
        );
    }

    function printExcel() {
        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/xls/corp/list'
        url_path += "?corp_name=" + search.corp_name;
        url_path += "&line_number=" + search.line_number;
        url_path += "&local_number=" + search.local_number;

        window.open(url_path, '_blank');
    }

    /*****  전체선택 Chechbox - 서비스 사용안함 회선 => 사용처리  *****/
    const [showAllcheckbox, setShowAllcheckbox] = useState(false);  // 전체 checkbox 보임
    const [resultparams, setResultparams] = useState({              // API 호출 구분
        type: '',
        searchData: '',
        centrix_auth: '',
        use_yn: '',
        discd: '',
    })
    const [checkedline, setCheckedline] = useState([]);             // 선택한 회선 idx 담는 배열

    const [progressState, setProgressState] = useState(false);      // 변경 처리과정

    // 전체 checkbox
    function AllcheckLines(e) {
        /**  선택했을 때  **/
        if (e.target.checked) {
            setCheckedline(lines.map(el => el.line_idx))
        } else {
            /**  해제했을 때  **/
            setCheckedline([])
        }
    }

    // 서비스 사용 변경 버튼
    function handleUseActive() {
        // 선택한 목록이 없을 때
        if (checkedline.length === 0) {
            alert('체크박스를 선택해주세요');
            return;
        }
        if (!window.confirm(`${resultparams.type === 'all' ? '모든 페이지' : '현재 페이지에서 선택한'} 회선(${resultparams.type === 'all' ? search.total_count : checkedline.length}개)을 서비스 사용으로 변경하시겠습니까?`)) {
            return;
        }
        setProgressState(true)
        // 선택한 목록 -> 서비스 사용 변경 API 호출
        axios.put(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/lines', { line_idx: checkedline }
            , {
                params: {
                    type: resultparams.type,
                    searchData: resultparams.type === 'all' ? resultparams.searchData : '',
                    centrix_auth: resultparams.type === 'all' ? resultparams.centrix_auth : '',
                    use_yn: resultparams.type === 'all' ? resultparams.use_yn : '',
                    discd: resultparams.type === 'all' ? resultparams.discd : '',
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getCookie("access_token")
                },
            }
        )
            .then(response => {
                setProgressState(false)
                // if(response.data.res_code===200){
                //     searchList(search.page);
                // }else{
                //     if(response.data.res_code===401){
                //         history.push('/login');
                //     }else{
                //         alert(response.data.res_msg);
                //     }
                // }
                if (response.status === 201) {
                    // 마지막 페이지에서 서비스 사용처리 했을 때, (부분/ 전체 적용 공통)
                    if (search.total_page_cnt === search.page && checkedline.length === lines.length) searchList(search.page - 1);
                    else searchList(search.page);
                } else {
                    if (response.status === 401) {
                        history.push('/login');
                    } else {
                        alert(response.data.res_msg);
                    }
                }
            })
            .catch(err => {
                setProgressState(false)
                alert('서비스 사용 변경 처리에 실패했습니다.')
            })
    }
    /******************************************************************/

    return (
        <div style={{ position: 'relative', height: 'calc(100% - 60px)' }}>
            {
                progressState ?
                    <div className="progressAlert">
                        <div>
                            서비스 사용 변경 작업 진행중 &nbsp;
                            <span className="loadingSpinner"></span>
                        </div>
                    </div>
                    : null
            }
            <SearchLine search={search} setSearchData={setSearchData} searchList={searchList} key_name='line' />
            <PageCount search={search} setSearchData={setSearchData} />
            <Table hover className="lineTable">
                <thead>
                    <tr style={{ width: "100%" }}>
                        <th style={{ width: "2%" }} >{showAllcheckbox ? <Input type="checkbox" id="all-chk" onChange={(e) => AllcheckLines(e)} checked={checkedline.length === lines.length ? true : false} /> : null}</th>
                        <th style={{ width: "3%" }} >idx</th>
                        <th style={{ textAlign: 'center' }}>고객사명</th>
                        <th style={{ textAlign: 'center' }}>회선명</th>
                        <th style={{ width: "7%" }}>전화번호</th>
                        <th style={{ width: "7%" }}>내선</th>
                        <th style={{ width: "5%" }}>주기(분)</th>
                        <th style={{ width: "7%" }}>영업시간</th>
                        <th style={{ width: "7%" }}>대표번호</th>
                        <th style={{ width: "5%" }}>접근권한</th>
                        <th style={{ width: "5%" }}>실행권한</th>
                        <th style={{ width: "10%" }}>최종콜백타임</th>
                        <th style={{ width: "10%" }}>최종녹취타임</th>
                        <th style={{ width: "5%" }}>서비스</th>
                        <th style={{ width: "5%" }}>사용</th>
                        <th style={{ width: "3%" }}></th>
                    </tr>
                </thead>

                <LineList info={lines} nowPage={search.nowPage} searchData={search.searchData} handleEdit={handleEdit} handleList={handleList} checkedline={checkedline} setCheckedline={setCheckedline} />
            </Table>
            <div className="paginationPosition">
                {lines.length === 0 ?
                    null
                    :
                    <div style={{ float: "left" }}>
                        <PageNav search={search} searchList={searchList} setSearchData={setSearchData} />
                        <RePagination searchList={searchList} setSearchData={setSearchData} currentPage={search.page} count={search.total_count} pageCount={search.total_page_cnt} />
                    </div>
                }
                <div style={{ float: "right", display: 'flex' }}>
                    {showAllcheckbox ?
                        <div style={{ borderRight: '1px solid #8d8d8d', display: 'flex', paddingRight: '10px' }}>
                            <Input type="select" style={{ width: '190px', height: '27px', margin: 0 }} bsSize="sm" id="use_yn" value={resultparams.type} onChange={(e) => setResultparams({ ...resultparams, type: e.target.value })} >
                                <option value="" >현재 페이지 선택 회선 {checkedline.length !== 0 ? `(${checkedline.length}개)` : ''}</option>
                                {checkedline.length === lines.length ?
                                    <option value="all" >모든 페이지 회선 ({search.total_count}개)</option>
                                    : null
                                }
                            </Input>
                            &nbsp;
                            <Button color="primary" size="sm" onClick={() => handleUseActive()}>서비스 사용 변경</Button>
                        </div>
                        : null
                    }

                    &nbsp;&nbsp;&nbsp;

                    <Button color="secondary" size="sm" onClick={() => printExcel()} >엑셀출력</Button>
                    {/* &nbsp;&nbsp;
                    <Button color="secondary" size="sm" onClick={(e) => handleEdit(e,0)} >신규등록</Button> */}
                    &nbsp;&nbsp;
                    <Button color="secondary" size="sm" onClick={(e) => handleRemove()}>&nbsp;&nbsp;해지&nbsp;&nbsp;</Button>
                </div>
            </div>
        </div>
    );
}

export default LineTable;