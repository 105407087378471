import { Button, InputGroup, Input, FormGroup, Col } from 'reactstrap';
import "../../css/search.css"
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function SearchLine(props) {

    let location = useLocation();

    const handleSearch = (e) => {
        props.searchList(1, props.search.searchData);
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            handleSearch(e);
        }
    }

    return (

        <FormGroup row className="pt-3 pb-3">
            <Col sm={12} style={{ marginBottom: '20px' }}><h3>회선목록</h3></Col>
            <Col sm={7}>
                <InputGroup>
                    {/* <Input type="text" className="search_box" id="line_number"  value={props.search.line_number} placeholder="전화번호" onChange={(e) => props.setSearchData("line_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>          
                    <Input type="text" className="search_box" id="local_number"  value={props.search.local_number} placeholder="내선번호" onChange={(e) => props.setSearchData("local_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
                    <Input type="text" className="search_box" id="corp_name"  value={props.search.corp_name} placeholder="고객사명" onChange={(e) => props.setSearchData("corp_name",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>                               */}

                    <div style={{ position: 'relative', flex: '1 1 auto' }}>
                        <Input type="text" style={{ width: '97%' }} className="search_box" id="searchData" value={props.search.searchData} placeholder="고객사명, 회선명, 전화번호 또는 내선을 입력하세요." onChange={(e) => props.setSearchData("searchData", e.target.value)} onKeyPress={(e) => handleKeyPress(e)} />
                        {
                            props.search.searchData.length ?
                                <FontAwesomeIcon icon={faTimes} style={{ position: 'absolute', right: '20px', top: '10px', cursor: 'pointer' }} onClick={() => props.setSearchData("searchData", '')} />
                                : null
                        }
                    </div>
                    <div className='d-flex'>
                        <Input type="select" className="search_box" id="use_yn" value={props.search.use_yn} onChange={(e) => props.setSearchData("use_yn", e.target.value)} onKeyPress={(e) => handleKeyPress(e)} >
                            <option value="" >서비스</option>
                            <option value="Y" >서비스 사용중</option>
                            <option value="N" >서비스 사용안함</option>
                        </Input>
                        <Input type="select" className="search_box" id="centrix_auth" value={props.search.centrix_auth} onChange={(e) => props.setSearchData("centrix_auth", e.target.value)} onKeyPress={(e) => handleKeyPress(e)} >
                            <option value="" >대표번호</option>
                            <option value="Y" >대표번호 사용중</option>
                            <option value="N" >대표번호 사용안함</option>
                        </Input>
                        <Input type="select" className="search_box" id="discd" value={props.search.discd} onChange={(e) => props.setSearchData("discd", e.target.value)} onKeyPress={(e) => handleKeyPress(e)} >
                            <option value="" >모든 회선</option>
                            <option value="0" >사용회선</option>
                            <option value="1" >해지회선</option>
                        </Input>
                    </div>

                    <Button onClick={(e) => handleSearch(e)} >
                        검색
                    </Button>
                </InputGroup>
                {/* <input  />           
                <Button color="secondary" className="btn" size="sm" onClick={(e) => handleSearch(e)} >검색</Button> */}
            </Col>
        </FormGroup>

        // <div style={{float: "right" ,padding: "10px"}}>
        //     <input type="text" className="search_box" id="line_number"  value={props.search.line_number} placeholder="전화번호" onChange={(e) => props.setSearchData("line_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>          
        //     <input type="text" className="search_box" id="local_number"  value={props.search.local_number} placeholder="내선번호" onChange={(e) => props.setSearchData("local_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
        //     <input type="text" className="search_box" id="corp_name"  value={props.search.corp_name} placeholder="고객사명" onChange={(e) => props.setSearchData("corp_name",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>          
        //     <Button color="secondary" size="sm" onClick={(e) => handleSearch(e)} > &nbsp;&nbsp;검색&nbsp;&nbsp;  </Button>
        // </div>

    );
}

export default SearchLine;
