import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'
import { useMediaQuery } from 'react-responsive';

import { Table, Button, Spinner } from 'reactstrap';
import '../../css/table.css';

import PageNav from '../PageNav'
import PageCount from "../PageCount";
import RePagination from "../RePagination";

import { getCookie } from "../../ReactCookie";

import SearchAuthCentrix from "./SearchAuthCentrix";
import AuthCentrixList from "./AuthCentrixList";

import sortAsc from "../../assets/sortAsc.png";
import sortDesc from "../../assets/sortDesc.png";

const base_search = {
  page: 1,
  limit: 20,
  total_page_cnt: 0,
  total_count: 0,
  searchData: "",
  captcha_yn: ""
}


function AuthCentrixTable(props) {

  const history = useHistory();

  const [checkedCentrixLoading, setCheckedCentrixLoading] = useState(false);
  const [checkedline, setCheckedline] = useState([]);  // 선택한 회선 담는 배열

  const [search, setSearch] = useState({
    // page: 1,
    // limit: 20,
    // total_page_cnt: 0,
    total_count: 0,
    searchData: '',
    captcha_yn: '',
    captcha_sort: 0,
    expiration_sort: 0,
  });
  const [lines, setLines] = useState([]);

  const [page, setPage] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [updateTime, setUpdateTime] = useState('');

  function searchList(num, searchData, captcha_yn, captcha_sort, expiration_sort) {
    setPage(num);
    setRefresh(true);
    // setSortType({
    //   captcha_sort: 0,
    //   expiration_sort: 0,
    // })
    let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/centrix/list';

    axios.get(url_path, {
      params: {
        // page: num, 
        // limit: search.limit, 
        searchData: searchData === undefined ? search.searchData : searchData,
        captcha_yn: captcha_yn === undefined ? search.captcha_yn : captcha_yn,
        captcha_sort: captcha_sort === undefined ? sortType.captcha_sort : captcha_sort,
        expiration_sort: expiration_sort === undefined ? sortType.expiration_sort : expiration_sort,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getCookie("access_token")
      }
    })
      .then(res => {
        if (res.data.res_code === 200) {
          setSearch(
            {
              ...search,
              // page: res.data.page,
              // limit: res.data.limit,
              total_count: res.data.total_count,
              searchData: res.config.params.searchData,
              captcha_yn: res.config.params.captcha_yn,
              captcha_sort: res.config.params.captcha_sort,
              expiration_sort: res.config.params.expiration_sort,
            }
          );

          if (res.data.data === null) {
            setLines([]);
          } else {
            setLines(res.data.data);
          }
          setCheckedline([])
        } else {
          if (res.data.res_code === 401) {
            history.push('/login');
          } else {
            alert(res.data.res_msg);
          }
        }
        setTimeout(() => {
          setRefresh(false);
          setUpdateTime(updateTimeText)
        }, 800)

      });
  }

  function updateTimeText() {
    const date = new Date();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    let updateDate = date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
    return updateDate
  }

  useEffect(() => {
    if (history.location.state && !history.location.state.nowPage && history.location.state.searchOption) searchList(history.location.state.searchOption.page, history.location.state.searchOption.searchData, history.location.state.searchOption.captcha_yn);
    else searchList(1, search.searchData, search.captcha_yn);
  }, []);

  function setSearchData(name, value) {
    setSearch(
      { ...search, [name]: value }
    );
  }

  const goToCentrixMultiple = async () => {
    if (checkedline.length === 0) {
      alert('체크박스를 선택해주세요');
      return;
    }
    if (!window.confirm('선택한 대표번호를 인증처리 하시겠습니까?')) {
      return;
    }
    setCheckedCentrixLoading(true);
    await Promise.all(
      checkedline.map(async el => {
        const itemButton = document.querySelector(`#item-${el.companyid}-${el.line_idx}`);
        if (itemButton) await itemButton.click();
      })
    )
  }

  useEffect(() => {
    if (checkedCentrixLoading && !checkedline.length) setCheckedCentrixLoading(false);
  }, [checkedCentrixLoading, checkedline])


  const [sortType, setSortType] = useState({
    captcha_sort: 0,
    expiration_sort: 0,
  })

  const onChangeSort = (name) => {
    setSortType(prev => {
      let tempSortType = { ...prev };
      tempSortType[name] = tempSortType[name] === 0 ? 1 : tempSortType[name] === 1 ? 2 : 0;
      const keys = Object.keys(tempSortType);
      keys.forEach(el => el !== name ? tempSortType[el] = 0 : null);
      return tempSortType;
    })
  }

  const isMobile = useMediaQuery({
    query: "(max-width: 575px)"
  })
  const isPC = useMediaQuery({
    query: "(min-width: 576px)"
  })

  return (
    <div style={{ position: 'relative', height: 'calc(100% - 60px)' }}>
      <SearchAuthCentrix
        search={search}
        setSearchData={setSearchData}
        searchList={searchList}
        setSortType={setSortType}
        refresh={refresh}
        sortType={sortType}
        updateTime={updateTime}
      />
      {/* <PageCount search={search} setSearchData={setSearchData}/> */}
      <div>
        {isMobile &&
          <p className="text-end">총 {search.total_count} 건</p>
        }
        {isPC &&
          <p className="text-end" style={{ position: 'absolute', top: '72px', right: '20px' }}>총 {search.total_count} 건</p>
        }
      </div>

      <Table hover className="authcentrixTable">
        <thead>
          <tr style={{ width: "100%" }}>
            <th style={{ width: "2%" }}></th>
            <th style={{ width: "5%" }}>no</th>
            <th style={{ width: "6%" }}>설정</th>
            <th style={{ width: "5%" }}>corp_idx</th>
            <th style={{ width: "5%" }}>companyID</th>
            <th style={{ textAlign: 'center' }}>고객사명</th>
            <th style={{ textAlign: 'center' }}>고센 대표번호</th>
            <th style={{ textAlign: 'center' }}>회선 비밀번호</th>
            <th>영업시간</th>
            <th style={{ width: "5%" }}>서비스</th>
            <th style={{ width: "5%" }}>사용</th>
            <th style={{ width: "5%" }}>인증상태</th>
            <th>
              <span style={{ cursor: 'pointer' }} onClick={() => { onChangeSort('captcha_sort'); searchList(1, undefined, undefined, sortType.captcha_sort === 0 ? 1 : sortType.captcha_sort === 1 ? 2 : 0, 0) }}>CAPTCHA 최종 인증시간</span>
              {
                sortType.captcha_sort !== 0 ?
                  <img src={sortType.captcha_sort === 1 ? sortAsc : sortDesc} alt={sortType.captcha_sort === 1 ? '오름차순 정렬' : '내림차순 정렬'} style={{ width: '15px', height: '15px', verticalAlign: sortType.captcha_sort === 1 ? 'sub' : 'top' }} />
                  : null
              }
            </th>
            <th>
              <span style={{ cursor: 'pointer' }} onClick={() => { onChangeSort('expiration_sort'); searchList(1, undefined, undefined, 0, sortType.expiration_sort === 0 ? 1 : sortType.expiration_sort === 1 ? 2 : 0) }}>인증 만료시간</span>
              {
                sortType.expiration_sort !== 0 ?
                  <img src={sortType.expiration_sort === 1 ? sortAsc : sortDesc} alt={sortType.expiration_sort === 1 ? '오름차순 정렬' : '내림차순 정렬'} style={{ width: '15px', height: '15px', verticalAlign: sortType.expiration_sort === 1 ? 'sub' : 'top' }} />
                  : null
              }
            </th>
            <th>최종 인증 관리자ID</th>
          </tr>
        </thead>
        <AuthCentrixList info={lines} searchList={searchList} search={search} page={page} limit={search.limit} checkedline={checkedline} setCheckedline={setCheckedline} />
      </Table>
      <div className="paginationPosition">
        {/**  2023.06 정렬하기위해 pagination 삭제  **/}
        {/* {lines.length === 0 ?
            null
          :
            <div style={{float: "left"}}>
              <PageNav search={search} searchList={searchList} setSearchData={setSearchData}/>
              <RePagination searchList={searchList} setSearchData={setSearchData} currentPage={search.page} count={search.total_count} pageCount={search.total_page_cnt} />
            </div>
          } */}
        <div style={{ float: "right", display: 'flex' }}>
          {
            checkedCentrixLoading ?
              <Button disabled color="danger" size="sm">&nbsp;&nbsp;인증처리 진행중 <Spinner size='sm' />&nbsp;&nbsp;</Button>
              :
              <Button outline color="danger" size="sm" onClick={goToCentrixMultiple}>&nbsp;&nbsp;선택 대표번호 인증처리&nbsp;&nbsp;</Button>
          }
        </div>
      </div>
    </div>
  );
}

export default AuthCentrixTable;