import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Col, Button } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios'

import { confirmAlert } from "react-confirm-alert";

import '../../css/LinePopup.css';

import { Link, useHistory } from "react-router-dom";
import { getCookie } from "../../ReactCookie";
import { Bg, CorpSearchPopup } from "../Popup"
import { useModal } from 'react-hooks-use-modal';

const blank_data = {
    line_idx: 0,
    corp_idx: 0,
    corp_name: "",
    line_name: "",
    line_number: "",
    fresh_login_pwd: "",
    local_number: "",
    fresh_pwd: "",
    polling_period: 30,
    use_yn: "Y",
    open_door: 0,
    close_door: 0,
    open_door_min: 0,
    close_door_min: 0,
    sms_yn: "N",
    group_name: "",
    acc_auth: "",
    exe_auth: 1,
    line_auth: "N",
    line_auth_level: 0,
    privacy_yn: "",
    privacy_time: "",
    reg_date: "",
    reg_time: "",
    callbackData: "",
    companyid: 0,
    customerid: "",
    checked: false,
    foward_number: "",
    number070: "",
    exten: "",
    callback_idx: "",
    line_memo: "",
    discd: 0,
    centrix_auth: "N"
}


const blank_data2 = {
    res_msg: "",
    foward_number: "",
    number070: "",
    exten: "",
}

const blank_data3 = {
    corp_name: "",
    companyid: 0,
}


function LineDetail(props) {

    const [data, setData] = useState(blank_data);
    const [userData, setUserData] = useState(blank_data2);
    const [corpData, setCorpData] = useState('blank_data3');
    const [prevDiscd, setPrevDiscd] = useState(blank_data.discd);
    const [hasCorpidx, setHasCorpidx] = useState(true);

    const [refreshKey, setRefreshKey] = useState(0);
    const [callback_data, setCallback_data] = useState([]);
    const [open, setOpen] = useState(false);
    const [Modal, popupOpen, close, isOpen] = useModal('root', {
        preventScroll: true,
        closeOnOverlayClick: false
    });

    const [prevmemo, setPrevmemo] = useState('');

    const history = useHistory();
    // Use the constructor with your `props.location.search` 
    const queryParams = new URLSearchParams(props.location.search);
    // Use the getters to grab a specific value

    const corp_name = queryParams.get("corp_name");
    const corp_idx = queryParams.get("corp_idx");

    useEffect(() => {
        const historyState = history.listen((location, action) => {
            if (action === 'POP') {
                return goList();
                //   history.push({pathname: '/corps', state: {searchOption: props.location.state.searchOption}});
                // history.push({pathname: '/corps', state: {searchOption: props.location.state.searchOption}})
            }
        })
        return () => historyState();
    }, [])

    useEffect(() => {
        if (corp_idx > 0 || data.corp_idx > 0) {

            let uri

            if (corp_idx > 0) {
                uri = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/corp/' + corp_idx;
            } else {
                uri = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/corp/' + data.corp_idx;
            }

            axios.get(uri, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getCookie("access_token")
                }
            }).then(res => {
                if (res.data.res_code === 200) {
                    //setCorpData(res.data.data);                        
                    setData(
                        {
                            ...data,
                            "corp_idx": res.data.data.corp_idx,
                            "corp_name": res.data.data.corp_name,
                            "companyid": res.data.data.companyid
                        }
                    );
                    if (res.data.data.discd === 1) {
                        alert("존재하지 않거나 해지 처리된 고객사의 회선정보입니다.");
                        setHasCorpidx(false);
                    }
                } else {
                    // setData(blank_data);
                    // alert("존재하지 않는 고객사 idx 입니다.");
                    // alert("존재하지 않거나 해지 처리된 고객사의 회선정보입니다.");
                    setHasCorpidx(false);
                    history.push("/line/0");
                }
            });

        } else {
            // console.log('b')
        }
    }, [corp_idx, data.corp_idx]);

    useEffect(() => {

        if (props.match.params.line_idx > 0) {

            let uri = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/line/' + props.match.params.line_idx;
            axios.get(uri
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getCookie("access_token")
                    }
                }
            )
                .then(res => {

                    if (res.data.res_code === 200) {

                        setData(res.data.data);
                        setPrevDiscd(res.data.data.discd)
                        if (res.data.data.line_memo !== '') setPrevmemo(res.data.data.line_memo)
                        axios.get(process.env.REACT_APP_PUBLIC_SERVER + `/mgr/centrix/forward/${props.match.params.line_idx}`
                            , {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + getCookie("access_token")
                                }
                            })
                            .then(result => {
                                if (result.data.res_code === 200) {
                                    setUserData({
                                        "res_msg": result.data.res_msg,
                                        "foward_number": result.data.foward_number,
                                        "number070": result.data.number070,
                                        "exten": result.data.exten,
                                    });
                                    //setData(res.data.data);
                                } else {
                                    setUserData(blank_data);
                                }
                            });

                    } else {
                        setData(blank_data);
                        setPrevDiscd(blank_data.discd)
                    }
                });

        } else {
            if (corp_name !== "" || corp_idx !== "") {
                setData(
                    {
                        ...data,
                        "corp_name": corp_name,
                        "corp_idx": corp_idx
                    }
                );
                setPrevDiscd(blank_data.discd)
            }
        }


        axios.get(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/callback/list', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getCookie("access_token")
            },
            params: {
                use_yn: "Y",
            }
        }).then(res => {
            if (res.data.res_code === 200) {
                if (res.data.data && res.data.data.length > 0) {
                    setCallback_data(res.data.data);

                    //setCallback_data([{ value: res.data.callback_idx, label: res.data.callback_host }]);                     
                    /*
                    setCallback_data(prevDatas => {
                        return [...new Set([...prevDatas, ...res.data.data.map(callback =>
                            [{value: callback.callback_idx, label: callback.callback_host + callback.callback_url}]
                        )
                        ])]
                    })
                    
                    res.data.data && res.data.data.map((callback, index) =>
                        setCallback_data([callback.callback_host])
                        //console.log(callback.callback_host)
                        //setCallback_data([...{ value: callback.callback_idx, label: callback.callback_host + callback.callback_url }])                                                                    
                    )
                    */
                }
            }
        }).catch(error => {
            // console.log("@@@@@@@@@@@@@@@@@@@@@@@@");            
        });
    }, [refreshKey]);



    function valueChange(e) {
        if (e.target.name === "line_number") {
            setData(
                {
                    ...data,
                    "line_number": e.target.value,
                    "customerid": e.target.value
                }
            );
        } else if (e.target.name === "acc_auth") {
            if (e.target.value === "전체") {
                setData(
                    {
                        ...data,
                        "acc_auth": e.target.value,
                        "line_auth_level": 1
                    }
                );
            }
            if (e.target.value === "개인" || e.target.value === "그룹") {
                setData(
                    {
                        ...data,
                        "acc_auth": e.target.value,
                    }
                );
            }
        } else {
            setData(
                {
                    ...data,
                    [e.target.name]: e.target.value
                }
            );
        }


    }

    const [updatememo, setUpdatememo] = useState(1);

    useEffect(() => {
        if (updatememo > 1) handleUpdate();
    }, [updatememo])

    function addMemodate() {
        const date = new Date();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();
        let second = date.getSeconds();

        month = month >= 10 ? month : '0' + month;
        day = day >= 10 ? day : '0' + day;
        hour = hour >= 10 ? hour : '0' + hour;
        minute = minute >= 10 ? minute : '0' + minute;
        second = second >= 10 ? second : '0' + second;

        let memoDate = date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;

        setData({
            ...data,
            "line_memo": data.line_memo + `  [${memoDate}]`
        })
        setUpdatememo(prev => prev + 1)
    }

    function handleUpdate() {
        //수정일때
        if (data.line_idx > 0) {
            // 회선 사용여부 변경일 때 (해지 -> 사용)
            // let submitData = {...data};
            let { captcha_yn, ...submitData } = data;
            if (data.discd != prevDiscd) {
                const date = new Date();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                let hour = date.getHours();
                let minute = date.getMinutes();
                let second = date.getSeconds();

                month = month >= 10 ? month : '0' + month;
                day = day >= 10 ? day : '0' + day;
                hour = hour >= 10 ? hour : '0' + hour;
                minute = minute >= 10 ? minute : '0' + minute;
                second = second >= 10 ? second : '0' + second;

                let memoDate = date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
                submitData.line_memo = data.line_memo + (data.line_memo !== '' ? '\r\n' : '') + '관리자 ' + getCookie("login_name") + ': 해당 회선 \'' + (prevDiscd === 0 ? '사용' : '해지') + '>' + (data.discd === '0' ? '사용' : '해지') + `' 변경  [${memoDate}]`;
            }
            axios.put(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/line/' + data.line_idx, submitData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getCookie("access_token")
                }
            }).then(response => {
                if (response.data.res_code === 200) {
                    //setData(blank_data );
                    response.data.data.fresh_pwd = "";
                    setData(response.data.data);
                    setPrevDiscd(response.data.data.discd)
                    setPrevmemo(response.data.data.line_memo)
                    setRefreshKey(refreshKey + 1);
                    // confirmAlert({
                    //     message: "회선 정보를 수정하였습니다.",
                    //     buttons: [
                    //         {
                    //         label: "확인",
                    //             // onClick: () => history.push('/corp/'+data.corp_idx)
                    //         }
                    //     ],
                    // }); 
                    confirmAlert({ message: "회선 정보를 수정하였습니다.", buttons: [] })
                    setTimeout(() => {
                        if (document.querySelector('.react-confirm-alert-overlay')) document.querySelector('.react-confirm-alert-overlay').click();
                    }, 1200)
                } else {
                    alert(response.data.res_msg);
                }
            });

            //신규등록일때
        } else {
            axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/join/line', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getCookie("access_token")
                }
            }).then(response => {
                if (response.data.res_code === 200) {
                    //setData(blank_data );
                    response.data.data.fresh_pwd = "";
                    setData(response.data.data);
                    setPrevDiscd(response.data.data.discd)
                    setPrevmemo(response.data.data.line_memo)
                    confirmAlert({
                        message: "새로운 회선 정보를 등록하였습니다.",
                        buttons: [
                            {
                                label: "확인",
                                // onClick: () => history.push('/corp/'+data.corp_idx)
                            }
                        ]
                    });
                    history.push("/line/" + response.data.data.line_idx);
                    setRefreshKey(refreshKey + 1);
                } else {
                    alert(response.data.res_msg);
                }
            });
        }
    }

    function goDetail(n) {
        //history.goBack();
        setHasCorpidx(true);
        if (n == "copy") {

            confirmAlert({
                //title: "등록된 콜백URL 을 삭제하시겠습니까?\n되돌릴수 없습니다.",
                message: "같은 고객사에 회선추가 하시겠습니까?",
                buttons: [
                    {
                        label: "예",
                        onClick: () => {
                            setData(blank_data);
                            setPrevDiscd(blank_data.discd)
                            setUserData(blank_data2);
                            history.push(`/line/0?corp_idx=${data.corp_idx}`);
                            setRefreshKey(refreshKey + 1);
                        }
                    },
                    {
                        label: "아니오"
                        // onClick: () => alert("Click No")
                    }
                ]
            });

        } else {

            confirmAlert({
                //title: "등록된 콜백URL 을 삭제하시겠습니까?\n되돌릴수 없습니다.",
                message: "신규 회선추가 하시겠습니까? 창이 초기화 됩니다.",
                buttons: [
                    {
                        label: "예",
                        onClick: () => {
                            setData(blank_data);
                            setPrevDiscd(blank_data.discd)
                            setUserData(blank_data2);
                            history.push("/line/0");
                        }
                    },
                    {
                        label: "아니오"
                        // onClick: () => alert("Click No")
                    }
                ]
            });

        }
    }

    function goCopyDetail() {

    }


    function goList() {
        //history.goBack();
        //history.go(1);

        if (history.location.pathname.split('/').includes('corp')) history.goBack();
        else if (!history.location.state) {
            history.push({
                pathname: "/lines",
                state: {
                    nowPage: 0,
                }
            });
        } else {
            history.push({
                pathname: "/lines",
                state: {
                    nowPage: props.location.state.nowPage,
                    searchData: props.location.state.searchData,
                    sms_yn: props.location.state.sms_yn,
                    use_yn: props.location.state.use_yn,
                    discd: props.location.state.discd,
                }
            })
        };

    }

    function privacyHandler() {
        var privacy = document.getElementById("privacy_yn");
        var yn = "";
        if (privacy.checked) {
            yn = "Y";
        } else {
            yn = "N";
        }
        setData(
            {
                ...data,
                "privacy_yn": yn
            }
        );
    }

    function checkBoxHandler() {
        var exe_auth_val = 0;
        for (var zz = 0; zz < 3; zz++) {
            var loc = Math.pow(2, zz);
            var exe01 = document.getElementById("exe_auth_" + loc);

            if (exe01.checked) {
                exe_auth_val += loc;
            }
        }

        setData(
            {
                ...data,
                "exe_auth": exe_auth_val
            }
        );

        var exe01 = document.getElementById("exe_auth_1")
        //console.log("exe01.checked",exe01.checked);

        var exe02 = document.getElementById("exe_auth_2")
        //console.log("exe02.checked",exe02.checked);

        var exe04 = document.getElementById("exe_auth_4")
        //console.log("exe04.checked",exe04.checked);

    }

    let timeLoop = () => {
        let timeArray = [];
        for (let i = 0; i < 24; i++) {
            timeArray.push(<option value={i} key={i}>{i}</option>);
        }
        return timeArray;
    }

    //let handlePopup = () => {
    //    setPopupOpen(prevCheck => !prevCheck)
    //}

    const pwdChangeButton = () => {
        popupOpen(true);
        //element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }

    const deleteCallBack = () => {
        confirmAlert({
            //title: "등록된 콜백URL 을 삭제하시겠습니까?\n되돌릴수 없습니다.",
            message: `등록된 콜백URL 을 삭제하시겠습니까?
            되돌릴수 없습니다.
            * 삭제 시 센트릭스 자동녹취 해지 할것`,
            buttons: [
                {
                    label: "예",
                    onClick: () =>
                        axios.get(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/centrix/unregister/' + data.line_idx, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + getCookie("access_token")
                            }
                        }).then(response => {
                            if (response.data.res_code === 200) {
                                //setData(blank_data );                                                                
                                setRefreshKey(refreshKey + 1)
                            } else {
                                alert(response.data.res_msg);
                            }
                        })
                },
                {
                    label: "아니오"
                    // onClick: () => alert("Click No")
                }
            ]
        });

    }


    function selectCorpData(idx, name) {
        setData(
            {
                ...data,
                "corp_idx": idx,
                "corp_name": name,
            }
        );
        if (idx) setHasCorpidx(true)
        setOpen(false);

    }

    function initialValue(e) {
        if (!e.target.value) setData({ ...data, [e.target.name]: 0 })
    }

    return (
        <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
            <Form>

                <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                    <Label for="lineIdx" sm={2}>회선idx</Label>
                    <Col sm={4}>
                        <Input type="text" name="line_idx" id="line_idx" value={data.line_idx || ''} readOnly="readonly" placeholder="색인번호입니다" onChange={(e) => valueChange(e)} />
                    </Col>
                    <Label for="lineIdx" sm={2}>회사idx</Label>
                    <Col sm={4}>
                        <Input type="text" name="corp_idx" id="corp_idx" value={data.corp_idx || ''} readOnly="readonly" placeholder="색인번호입니다" onChange={(e) => valueChange(e)} />
                    </Col>

                </FormGroup>

                <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                    <Label for="corpName" sm={2}>고객사명(*)</Label>
                    <Col sm={4}>
                        {/* <Input type="text" style={{ imeMode: false }} name="corp_name" id="corp_name" value={data.corp_name || ''} placeholder="고객사명을 입력해주세요" onChange={(e) => valueChange(e)}
                    readOnly={corp_name !== undefined && corp_name !== '' && corp_name !== null ? true : false}
                        /> */}

                        <Input type="text" name="corp_name" id="corp_name" value={data.corp_name || ''}
                            // onChange={(e) => valueChange(e)}                            
                            onClick={() => {
                                corp_idx > 0 ?
                                    <></> : setOpen(true)

                            }}
                            placeholder="고객사명을 선택해주세요" readOnly />
                        {/* <input type="hidden" name="corp_idx" value={data.corp_idx || '0'}/>         */}
                    </Col>

                    <Label for="corpName" sm={2}>회선명</Label>
                    <Col sm={4}>
                        <Input type="text" style={{ imeMode: false }} name="line_name" id="line_name" value={data.line_name || ''} placeholder="회선명을 입력해주세요" onChange={(e) => valueChange(e)} />
                    </Col>
                </FormGroup>


                <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                    <Label for="line_number" sm={2}>전화번호(*)</Label>
                    <Col sm={4}>
                        <Input type="text" style={{ imeMode: false }} name="line_number" id="line_number" value={data.line_number || ''} placeholder="전화번호를 입력해주세요" onChange={(e) => valueChange(e)} />
                    </Col>
                    <Label for="local_number" sm={2}>내선번호(*)</Label>
                    <Col sm={4}>
                        <Input type="text" style={{ imeMode: false }} name="local_number" id="local_number" value={data.local_number || ''} placeholder="내선번호를 입력해주세요" onChange={(e) => valueChange(e)} />
                    </Col>
                </FormGroup>


                {/* 
                <FormGroup row className="mb-1">
                <Label for="loginPasswd" sm={2}>로그인비밀번호(*)</Label>
                <Col sm={10}>
                <Input type="password" name="fresh_login_pwd" id="fresh_login_pwd"  value={data.fresh_login_pwd|| ''} placeholder="비밀번호 변경시 입력해주세요" onChange={(e) => valueChange(e)} />
                </Col>
                </FormGroup>
                */}


                <FormGroup row className="mb-1 mt-0 ms-0 me-0" style={{ alignItems: 'center' }}>
                    <Label for="corpPasswd" sm={2}>전화비밀번호(*)</Label>
                    <Col sm={4}>
                        <Input type="text" name="fresh_pwd" id="fresh_pwd" value={data.fresh_pwd || ''} placeholder="회선 비밀번호(센트릭스 웹사이트 비밀번호 동일)" onChange={(e) => valueChange(e)} />
                        {/* <Button type="button" onClick={handlePopup}>비밀번호 변경</Button> */}
                        {/* <Button type="button" onClick={open}>OPEN</Button> */}
                        {/* <Button type="button" onClick={pwdChangeButton}>비밀번호 변경</Button> */}
                        <Modal>
                            <div className="pwdChangePopup">
                                <div className="text-end p-2">회선번호 : {data.line_number}
                                    <Button type="button" className="btn ms-2" color="success" onClick={close}>팝업닫기</Button>
                                </div>
                                <iframe className="pwdChangeIframe" src={`https://centrex.uplus.co.kr/premium/backoffice/pwc.html?id=${data.line_number}`}></iframe>

                            </div>
                        </Modal>
                    </Col>
                    <Label for="line_auth" sm={2}>사용자 구분</Label>
                    <Col sm={4}>
                        <Input type="select" name="line_auth" id="line_auth" value={data.line_auth === 'Y' ? "Y" : "N" || ''} onChange={(e) => valueChange(e)}>
                            <option value="N" >일반 사용자</option>
                            <option value="Y" >관리자</option>
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup row className="mb-1 mt-0 ms-0 me-0" style={{ alignItems: 'center' }}>
                    <Label for="acc_auth" sm={2}>접근권한</Label>
                    <Col sm={4}>
                        <Input type="select" name="acc_auth" id="acc_auth" value={data.acc_auth || ''} onChange={(e) => valueChange(e)}>
                            <option value="" >선택안함</option>
                            <option value="전체" >전체</option>
                            <option value="그룹" >그룹</option>
                            <option value="개인" >개인</option>
                        </Input>
                    </Col>
                    <Label for="ip200" sm={2}>실행권한</Label>
                    <Col sm={4}>
                        <div>
                            <Label className="me-2"><input type='checkbox' id='exe_auth_1' name='exe_auth_1' value='PLAY' readOnly onClick={() => checkBoxHandler()} checked={((data.exe_auth & 1) > 0) ? true : false} /> 재생 </Label>
                            <Label className="me-2"><input type='checkbox' id='exe_auth_2' name='exe_auth_2' value='DOWN' readOnly onClick={() => checkBoxHandler()} checked={((data.exe_auth & 2) > 0) ? true : false} /> 다운 </Label>
                            <Label className="me-2"><input type='checkbox' id='exe_auth_4' name='exe_auth_4' value='DEL' readOnly onClick={() => checkBoxHandler()} checked={((data.exe_auth & 4) > 0) ? true : false} /> 삭제 </Label>
                        </div>
                    </Col>
                </FormGroup>

                <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                    <Label for="groupName" sm={2}>그룹명</Label>
                    <Col sm={4}>
                        <Input type="text" style={{ imeMode: false }} name="group_name" id="group_name" value={data.group_name || ''} placeholder="그룹명을 입력해주세요" onChange={(e) => valueChange(e)} />
                    </Col>
                    <Label for="centrix_auth" sm={2}>대표번호 사용여부</Label>
                    <Col sm={4}>
                        <Input type="select" name="centrix_auth" id="centrix_auth" value={(data.centrix_auth || '')} onChange={(e) => valueChange(e)}>
                            <option value="N" >사용안함</option>
                            <option value="Y" >사용</option>
                        </Input>
                    </Col>
                </FormGroup>


                <FormGroup row className="mb-1 mt-0 ms-0 me-0" style={{ alignItems: 'center' }}>
                    <Label for="PollingPeriod" sm={2}>주기(분)</Label>
                    <Col sm={4}>
                        <Input type="text" name="polling_period" id="polling_period" value={data.polling_period || ''} onChange={(e) => valueChange(e)} placeholder="분단위 폴링주기를 입력해주세요" />
                    </Col>

                    <Label for="open_door" sm={2} >영업시간</Label>
                    <Col style={{ width: '5%' }}>
                        <Input type="number" name="open_door" id="open_door" value={data.open_door + '' || ''} onChange={(e) => valueChange(e)} onBlur={(e) => initialValue(e)} min="0" max="24">
                            {/* {timeLoop()} */}
                        </Input>
                    </Col>
                    시
                    <Col style={{ width: '5%' }}>
                        <Input type="number" name="open_door_min" id="open_door_min" value={data.open_door_min + '' || ''} onChange={(e) => valueChange(e)} onBlur={(e) => initialValue(e)} min="0" max="60">
                            {/* {timeLoop()} */}
                        </Input>
                    </Col>
                    <Label for="Dash" style={{ width: '4%', paddingLeft: '0' }}>분 ~</Label>
                    <Col style={{ width: '5%' }}>
                        <Input type="number" name="close_door" id="close_door" value={data.close_door + '' || ''} onChange={(e) => valueChange(e)} onBlur={(e) => initialValue(e)} min="0" max="24">
                            {/* {timeLoop()} */}
                        </Input>
                    </Col>
                    시
                    <Col style={{ width: '5%' }}>
                        <Input type="number" name="close_door_min" id="close_door_min" value={data.close_door_min + '' || ''} onChange={(e) => valueChange(e)} onBlur={(e) => initialValue(e)} min="0" max="60">
                            {/* {timeLoop()} */}
                        </Input>
                    </Col>
                    <Label for="Dash" style={{ width: '5%', paddingLeft: '0' }}>분</Label>
                </FormGroup>

                {/* <FormGroup row className="mb-1" style={{ lineHeight:'40px' }}> */}

                {/* </FormGroup> */}


                <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                    <Label for="sms_yn" sm={2}>SMS사용여부</Label>
                    <Col sm={4}>
                        <Input type="select" name="sms_yn" id="sms_yn" value={data.sms_yn || ''} onChange={(e) => valueChange(e)}>
                            <option value="" >선택안함</option>
                            <option value="N" >사용안함</option>
                            <option value="Y" >사용</option>
                        </Input>
                    </Col>

                    <Label for="use_yn" sm={2}>서비스 사용여부</Label>
                    <Col sm={4}>
                        <Input type="select" name="use_yn" id="use_yn" value={data.use_yn || ''} onChange={(e) => valueChange(e)}>
                            <option value="" >선택안함</option>
                            <option value="Y" >사용</option>
                            <option value="N" >사용안함</option>
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup row className="mb-1 mt-0 ms-0 me-0" style={{ alignItems: 'center' }}>
                    <Label for="privacy_yn" sm={2}>개인정보동의</Label>
                    <Col sm={2}>
                        <Label><input type='checkbox' id='privacy_yn' name='privacy_yn' readOnly onClick={() => privacyHandler()} checked={(data.privacy_yn === 'Y') ? true : false} /> 개인정보 동의 </Label>&nbsp;&nbsp;
                    </Col>
                    <Col sm={2}>
                        <Input type="input" name="privacy_time" id="privacy_time" value={data.privacy_time || ''} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="개인정보 동의일" ></Input>
                    </Col>
                    <Label for="discd" sm={2}>회선 사용여부</Label>
                    <Col sm={4}>
                        <Input type="select" name="discd" id="discd" value={(data.discd || '')} onChange={(e) => valueChange(e)}>
                            <option value="0" >사용</option>
                            <option value="1" >해지</option>
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                    <Label for="line_memo" sm={2}>메모</Label>
                    <Col sm={10}>
                        <Input type="textarea" name="line_memo" id="line_memo" value={data.line_memo || ''} onChange={(e) => valueChange(e)}
                            style={{ height: '90px', resize: 'none', textOverflow: 'scroll' }} />
                    </Col>
                </FormGroup>


                <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                    <Label for="ip200" sm={2}>IQ200 COMPANYID</Label>
                    <Col sm={4}>
                        <Input type="text" name="companyid" id="companyid" value={data.companyid || ''} onChange={(e) => valueChange(e)} placeholder="IQ200 Company ID를 입력해주세요" />
                    </Col>

                    <Label for="ip200" sm={2}>IQ200 회선</Label>
                    <Col sm={4}>
                        <Input type="text" name="customerid" id="customerid" value={data.customerid || ''} onChange={(e) => valueChange(e)} placeholder="IQ200 Customer ID를 입력해주세요" />
                    </Col>
                </FormGroup>

                <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                    <Label for="callback_idx" sm={2}>콜백URL 지정</Label>
                    <Col sm={4} style={{ lineHeight: '40px' }}>
                        {data.callback_status === "OK" ?
                            <>
                                <Button type="button" className="btn me-1" color="danger">수정불가</Button>
                                <span className="small" style={{ color: 'red' }}>* 현 Centrex 콜백상태 삭제 후 수정 가능</span>
                            </>
                            :
                            <>
                                <Input type="select" name="callback_idx" id="callback_idx" value={data.callback_idx || ''} onChange={(e) => valueChange(e)}>
                                    <option value="">콜백URL 선택</option>
                                    {
                                        callback_data.map((data, index) =>
                                            <option key={index} value={data.callback_idx}>=={data.callback_name}== | {data.callback_host}:{data.callback_port}  | {data.callback_url}</option>
                                        )
                                    }
                                </Input>
                            </>
                        }
                    </Col>
                </FormGroup>
                <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                    <Label for="registerDay" sm={2}>등록일시</Label>
                    <Col sm={4}>
                        <Input type="text" name="reg_date" id="reg_date" value={data.reg_date + ' ' + data.reg_time || ''} readOnly="readonly" placeholder="등록일입니다" onChange={(e) => valueChange(e)} />
                    </Col>
                    <Label for="registerDay" sm={2}>최근 수정일시</Label>
                    <Col sm={4}>
                        <Input type="text" name="last_date" id="last_date" value={data.last_date || ''} readOnly="readonly" placeholder="수정일입니다" onChange={(e) => valueChange(e)} />
                    </Col>
                </FormGroup>

                <hr />

                <div className="d-flex">
                    <div className="w-50 border-end border-2 border-rbga(0,0,0,1)">
                        {/* 콜백 등록 상태 표시 */}
                        <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                            <Label for="callBackHost" sm={4}>현 Centrex 콜백상태</Label>
                            <Col sm={4} className="">
                                {data.callback_status === "OK" ?
                                    <>
                                        <Button type="button" className="btn me-1" color="success">등록완료</Button>
                                        <Button color="danger" outline onClick={deleteCallBack}>삭제</Button>
                                    </>
                                    : <Button type="button" className="btn" color="danger">미등록</Button>}
                            </Col>
                        </FormGroup>

                        {data.callback_status === "OK" ? <>

                            <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                                <Label for="callBackHost" sm={4}>콜백 URL</Label>
                                <Col sm={4} style={{ lineHeight: '38px' }}>
                                    {data.callback_url}
                                </Col>
                            </FormGroup>

                            <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                                <Label for="callBackHost" sm={4}>콜백 HOST / PORT</Label>
                                <Col sm={4} style={{ lineHeight: '38px' }}>
                                    {data.callback_host} / {data.callback_port}
                                </Col>
                            </FormGroup>

                        </> : ""}

                        <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                            <Label for="last_callback_time" sm={4}>최종콜백타임</Label>
                            <Col sm={4}>
                                <Input type="text" name="last_callback_time" id="last_callback_time" value={data.last_callback_time || ''} readOnly="readonly" placeholder="최종콜백타임" onChange={(e) => valueChange(e)} />
                            </Col>
                        </FormGroup>

                        <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                            <Label for="last_record_time" sm={4}>최종녹취타임</Label>
                            <Col sm={4}>
                                <Input type="text" name="last_record_time" id="last_record_time" value={data.last_record_time || ''} readOnly="readonly" placeholder="최종녹취타임" onChange={(e) => valueChange(e)} />
                            </Col>
                        </FormGroup>
                    </div>

                    <div className="w-50">
                        <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                            <Label for="callBackHost" sm={4}>사용자 정보 조회</Label>
                            <Col sm={4} style={{ lineHeight: '38px' }}>
                                {userData.res_msg === "OK" ? <><Button type="button" className="btn" color="success">로그인 성공</Button></> : <><Button type="button" className="btn" color="danger">로그인 실패</Button></>}
                            </Col>
                        </FormGroup>

                        <FormGroup row className="mb-1 mt-0 ms-0 me-0">
                            {userData.res_msg === "OK" ?
                                <>
                                    <Label for="callBackHost" sm={4}>070번호</Label>
                                    <Col sm={6} style={{ lineHeight: '38px' }}>
                                        {userData.number070 || ''}
                                    </Col>

                                    <Label for="callBackHost" sm={4}>내선번호</Label>
                                    <Col sm={6} style={{ lineHeight: '38px' }}>
                                        {userData.exten || ''}
                                    </Col>

                                    <Label for="callBackHost" sm={4}>착신전환번호</Label>
                                    <Col sm={6} style={{ lineHeight: '38px' }}>
                                        {userData.foward_number || '없음'}
                                    </Col>

                                </>
                                : ""}
                        </FormGroup>
                    </div>
                </div>

                <hr />

                <FormGroup check row className="mb-1">
                    {/* <Col sm={{ size: 10, offset: 2 }}> */}
                    <Col sm={12} className="text-end ps-0" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Button color="danger" onClick={() => goDetail('new')} style={{ marginRight: 'auto' }}>
                                신규등록
                            </Button>
                            {hasCorpidx ?
                                <Button className="ms-2" color="primary" onClick={() => goDetail('copy')} style={{ marginRight: 'auto' }}>
                                    추가등록
                                </Button>
                                : null
                            }

                        </div>


                        <div className="d-flex">
                            {/* <Button onClick={() => handleUpdate()}> */}
                            {hasCorpidx ?
                                <Button onClick={() => data.line_memo !== '' && data.line_memo.toString().split(' ').join('').split('\n').join('') !== prevmemo.toString().split(' ').join('').split('\n').join('') ? addMemodate() : handleUpdate()}>
                                    {data.line_idx > 0 ? "수정" : "등록"}
                                </Button>
                                : <p style={{ color: 'red', margin: 0, lineHeight: '30px' }}>존재하지 않거나 해지 처리된 고객사의 회선정보입니다.</p>
                            }

                            <Button className="ms-2" onClick={() => goList()}>목록</Button>

                            {/* <Button className="ms-2">
                                <Link to={{                
                                    pathname: '/lines/'
                                    ,state: { nowPage : props.location.state.nowPage }
                                }}>                    
                                    목록2
                                </Link>                                
                            </Button> */}

                        </div>
                    </Col>
                </FormGroup>
            </Form>

            {open === true ? (
                <>
                    <CorpSearchPopup type={"info"} open={open} close={setOpen} timeout={1000} cookie={getCookie("access_token")} selectCorpData={selectCorpData}></CorpSearchPopup>
                    <Bg></Bg>
                </>
            ) : <>{open}</>}
        </div>
    );
}

export default LineDetail;